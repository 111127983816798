export const environment = {
  production: true,
  algoliaAppId: 'Q8CUPV1RET',
  api: 'https://api-uat.incendi.io',
  authConfig: {
    clientID: 'VR8u20pr2oOtsdA0lIn8OPMiM6FSTBF6',
    domain: 'login-uat.incendi.io'
  },
  contentfulApiUrl: 'https://australia-southeast1-incendi-uat.cloudfunctions.net/contentful_api',
  cookiesDomain: 'incendi.io',
  firebase: {
    apiKey: 'AIzaSyDuz1nH9WG9yyw4Esb07Rww_KAogCUXvQU',
    appId: '1:895219896870:web:cae7db7a55a9dee2c28ce7',
    authDomain: 'incendi-uat.firebaseapp.com',
    messagingSenderId: '895219896870',
    projectId: 'incendi-uat',
    storageBucket: 'incendi-uat.appspot.com'
  },
  gtmId: 'GTM-TTR6CMJ',
  zendeskKey: 'adf89b4e-44d8-40c6-8b58-f0188d73fefe'
};
